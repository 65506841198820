angular
  .module('app')
  .component('irishAbroadHeader', {
    templateUrl: 'app/header/header.html',
    controller: HeaderController,
    controllerAs: 'headerController',
  });

function HeaderController() {
  const vm = this;

  vm.navbarOpen = false;
  vm.toggleNavbar = toggleNavbar;

  function toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
}
