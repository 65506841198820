angular
  .module('app')
  .service('PlayerSeasonDataService', playerSeasonDataService);

function playerSeasonDataService($cacheFactory) {
  const playerSeasonDataCache = $cacheFactory('PlayerSeasonData');

  this.addPlayerSeasonData = addPlayerSeasonData;
  this.getPlayerSeasonData = getPlayerSeasonData;

  function addPlayerSeasonData(playerSlug, seasonData) {
    if (angular.isUndefined(playerSeasonDataCache.get(playerSlug))) {
      const playerSeasonData = {}
      angular.forEach(seasonData.seasons, function(season) {
        this[season.seasonRef] = season;
      }, playerSeasonData);
      playerSeasonData['default'] = seasonData.seasons[seasonData.seasons.length - 1];
      playerSeasonDataCache.put(playerSlug, playerSeasonData);
    }
  }

  function getPlayerSeasonData(playerSlug, seasonRef) {
    const playerSeasonData = playerSeasonDataCache.get(playerSlug);
    return playerSeasonData[seasonRef];
  }
}
