angular
  .module('app')
  .directive('startingXi', startingXI);

function startingXI() {
  return {
    restrict: 'EA',
    scope: {
      startingPlayers: '=startingPlayers',
      club: '=club',
    },
    templateUrl: 'app/gameDay/details/lineup/startingXI/startingXI.html',
  };
}
