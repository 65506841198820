angular
  .module('app')
  .component('clubFilterModalComponent', {
  templateUrl: 'clubFilterModalComponent.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  controller: clubFilterModalComponentController,
  controllerAs: 'clubFilterModalComponentCtrl'
});

function clubFilterModalComponentController($log) {
  const vm = this;
  const ALL_STARTING_PLAYERS = 'All starting players';
  const ALL_SUBSTITUTE_PLAYERS = 'All substitute players';

  vm.gameOptions = ['All', 'Home', 'Away'];
  vm.resultOptions = ['All', 'Home Win', 'Away Win', 'Draw'];

  vm.$onInit = onInit;
  vm.resetFilter = resetFilter;
  vm.applyFilter = applyFilter;
  vm.cancel = cancel;
  vm.processStartingXIFilter = processStartingXIFilter;
  vm.processSubstituteFilter = processSubstituteFilter;

  function onInit() {
    vm.filters = vm.resolve.filters;
    vm.competitionOptions = ['All', ...vm.resolve.competitions];
    vm.startingPlayersOptions = [{
      label: ALL_STARTING_PLAYERS,
      selected: (vm.filters.startingPlayers === 'All')
    }];
    vm.resolve.startingPlayers.forEach(sP => {
      vm.startingPlayersOptions.push({
        label: sP,
        selected: (angular.isArray(vm.filters.startingPlayers) && vm.filters.startingPlayers.includes(sP))
      });
    });
    vm.substitutePlayersOptions = [{
      label: ALL_SUBSTITUTE_PLAYERS,
      selected: (vm.filters.substitutePlayers === 'All')
    }];
    vm.resolve.substitutePlayers.forEach(sP => {
      vm.substitutePlayersOptions.push({
        label: sP,
        selected: (angular.isArray(vm.filters.substitutePlayers) && vm.filters.substitutePlayers.includes(sP))
      });
    });
  }

  function resetFilter() {
    vm.close({$value: {
      competition: 'All',
      game: 'All',
      result: 'All',
      startingPlayers: 'All',
      substitutePlayers: 'All'
    }});
  }

  function applyFilter() {
    if (!vm.startingPlayersOptions[0].selected) {
      vm.filters.startingPlayers = [];
      vm.startingPlayersOptions.forEach((option, index) => {
        if (index > 0 && option.selected) {
          vm.filters.startingPlayers.push(option.label);
        }
      })
    } else {
      vm.filters.startingPlayers = 'All';
    }
    if (!vm.substitutePlayersOptions[0].selected) {
      vm.filters.substitutePlayers = [];
      vm.substitutePlayersOptions.forEach((option, index) => {
        if (index > 0 && option.selected) {
          vm.filters.substitutePlayers.push(option.label);
        }
      })
    } else {
      vm.filters.substitutePlayers = 'All';
    }
    vm.close({$value: vm.filters});
  }

  function cancel() {
    vm.dismiss({$value: 'cancel'});
  }

  function processStartingXIFilter(startingXIOption) {
    if (startingXIOption === ALL_STARTING_PLAYERS) {
      vm.startingPlayersOptions.forEach((option, index) => {
        if (index > 0) {
          option.selected = false;
        }
      })
    } else {
      vm.startingPlayersOptions[0].selected = false;
    }
  }

  function processSubstituteFilter(substituteOption) {
    if (substituteOption === ALL_SUBSTITUTE_PLAYERS) {
      vm.substitutePlayersOptions.forEach((option, index) => {
        if (index > 0) {
          option.selected = false;
        }
      })
    } else {
      vm.substitutePlayersOptions[0].selected = false;
    }

  }
}
