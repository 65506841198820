angular
  .module('app')
  .directive('clubLink', clubLink);

function clubLink() {
  return {
    restrict: 'E',
    scope: {
      club: '=club'
    },
    templateUrl: 'app/common/directives/clubLink/clubLink.html'
  };
}
