angular
  .module('app')
  .directive('positionLink', positionLink);

function positionLink() {
  return {
    restrict: 'E',
    scope: {
      position: '=position'
    },
    templateUrl: 'app/common/directives/positionLink/positionLink.html'
  };
}
