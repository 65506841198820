angular
  .module('app')
  .factory('ClubsService', clubsService);

function clubsService($resource, $cacheFactory) {
  const clubsCache = $cacheFactory('clubs');
  return $resource('api/clubs/:slug', {slug: '@slug'}, {
    'getClubs': {method: 'GET', cache: clubsCache, isArray: true},
    'getClub': {method: 'GET', cache: clubsCache}
  });
}
