angular
  .module('app')
  .component('playerFilterModalComponent', {
  templateUrl: 'playerFilterModalComponent.html',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
  controller: playerFilterModalComponentController,
  controllerAs: 'playerFilterModalComponentCtrl'
});

function playerFilterModalComponentController() {
  const vm = this;

  vm.gameOptions = ['All', 'Home', 'Away'];
  vm.resultOptions = ['All', 'Home Win', 'Away Win', 'Draw'];
  vm.appearanceOptions = ['All', 'Started', 'Completed', 'Substituted', 'Substitute', 'Introduced', 'Unused Substitute'];
  vm.scoringOptions = ['All', 'Scored', 'Did Not Score'];
  vm.disciplineOptions = ['All', 'Not disciplined', 'Booked', 'Sent Off'];

  vm.$onInit = onInit;
  vm.resetFilter = resetFilter;
  vm.applyFilter = applyFilter;
  vm.cancel = cancel;

  function onInit() {
    vm.filters = vm.resolve.filters;
    vm.competitionOptions = ['All', ...vm.resolve.competitions];
  }

  function resetFilter() {
    vm.close({$value: {
      competition: 'All',
      game: 'All',
      result: 'All',
      appearance: 'All',
      scoring: 'All',
      discipline: 'All'
    }});
  }

  function applyFilter() {
    vm.close({$value: vm.filters});
  }

  function cancel() {
    vm.dismiss({$value: 'cancel'});
  }
}
