angular
  .module('app')
  .service('PostsService', postsService);

function postsService($http, $rootScope) {
  const postsUrl = 'https://www.googleapis.com/blogger/v3/blogs/6729460513040542953/posts';
  const thisService = this;
  thisService.pageToken = '';

  const config = {
    method: 'GET',
    params: {
      key: 'AIzaSyCQGodOQz_ISgHCeppOskqTJDXiW9WJ2kg',
    },
  };

  this.getRecentPosts = getRecentPosts;
  this.getNextPosts = getNextPosts;

  function getRecentPosts() {
    thisService.pageToken = '';
    return getNextPosts();
  }

  function getNextPosts() {
    const url = new URL(postsUrl);
    if (thisService.pageToken != '') {
      url.searchParams.append('pageToken', thisService.pageToken);
    }
    return $http.get(url.toString(), config)
         .then(response => {
          thisService.pageToken = response.data.nextPageToken;
          return response.data.items;
        });
  }
}
