angular
  .module('app')
  .directive('playersGroup', playersGroup);

function playersGroup() {
  return {
    restrict: 'E',
    scope: {
      playersByLetter: '=playersByLetter'
    },
    templateUrl: 'app/players/playersGroup/playersGroup.html'
  };
}
