angular
  .module('app')
  .directive('pageTitle', pageTitle);

function pageTitle() {
  return {
    restrict: 'E',
    scope: {
      key: '=key',
      icon: '=icon'
    },
    templateUrl: 'app/common/directives/pageTitle/pageTitle.html'
  };
}
