angular
  .module('app')
  .controller('PositionController', positionController);

function positionController($document, $timeout, NgMap, positionData) {
  const vm = this;

  vm.positionHeight = 0;
  vm.showInfoWindow = showInfoWindow;
  

  vm.position = {
    name: positionData.position.name,
    players: positionData.players
  };
  $timeout(displayMap, 1000);

  function displayMap() {
    vm.positionHeight = angular.element($document[0].getElementById('position-div'))[0].scrollHeight; 
    vm.map = NgMap.initMap('position-ng-map');
  }

  function showInfoWindow(event, team) {
    vm.club = team.name;
    vm.stadium = team.stadiumName;
    vm.players = vm.position.players
                            .filter(p => p.signedTo.name === team.name)
                            .map(p => p.player.name);

    vm.map.showInfoWindow('position-info-window', this); // eslint-disable-line angular/controller-as-vm
  }
}
