angular
  .module('app')
  .controller('ClubsController', clubsController);

function clubsController(clubsData) {
  const vm = this;

  vm.clubsByTournament = [];
  vm.filter = '';

  vm.filterClubs = filterClubs;
  vm.clearFilter = clearFilter;

  let clubsByTournament = clubsData;
  filterClubs();

  function clearFilter() {
    vm.filter = '';
    filterClubs();
  }

  function filterClubs() {
    vm.clubsByTournament = [];
    let count = 0;
    clubsByTournament.forEach(tournamentClubs => {
      const vmClubsByTournament = {
        tournament: tournamentClubs.tournament,
        isOpen: (count < 2),
        clubs: []
      };
      count++;
      tournamentClubs.clubs.forEach(club => {
        const addClub = (vm.filter.length === 0 || club.name.toLowerCase().indexOf(vm.filter.toLowerCase()) > -1);
        if (addClub) {
          vmClubsByTournament.clubs.push(club);
        }
      });
      if(vmClubsByTournament.clubs.length > 0) {
        vmClubsByTournament.isOpen = (vmClubsByTournament.isOpen) || (vmClubsByTournament.clubs.length < 10);
        vm.clubsByTournament.push(vmClubsByTournament);
      }
    });
  }
}
