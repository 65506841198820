angular
  .module('app')
  .controller('ClubSeasonGamesController', clubSeasonGamesController);

function clubSeasonGamesController(clubSeasonData, $stateParams, $uibModal) {
  const vm = this;

  const ALL = 'All';

  vm.filters = {
    competition: ALL,
    game: ALL,
    result: ALL,
    startingPlayers: ALL,
    substitutePlayers: ALL
  };

  vm.openFilter = openFilter;

  vm.seasonRef = $stateParams.seasonRef === '' ? null : $stateParams.seasonRef;
  vm.clubSlug = $stateParams.slug;
  vm.games = clubSeasonData.games;

  function openFilter() {
    const modalInstance = $uibModal.open({
      animation: true,
      component: 'clubFilterModalComponent',
      resolve: {
        filters: () => vm.filters,
        competitions: () => Array.from(new Set(clubSeasonData.games.map(g => g.competition.name))),
        startingPlayers: () => Array.from(new Set(clubSeasonData.games.filter(g => g.startingXI.length > 0).flatMap(g => g.startingXI).map(p => p.player.name))),
        substitutePlayers: () => Array.from(new Set(clubSeasonData.games.filter(g => g.substitutes.length > 0).flatMap(g => g.substitutes).map(p => p.player.name)))
      }
    });

    modalInstance.result.then((filters) => {
      vm.filters = filters;
      vm.games = clubSeasonData.games
                               .filter(competitionFilter)
                               .filter(gameFilter)
                               .filter(resultFilter)
                               .filter(startingPlayerFilter)
                               .filter(substitutePlayerFilter);

    }, () => angular.noop);
  }

  function competitionFilter(game) {
    return [ALL, game.competition.name].includes(vm.filters.competition);
  }

  function gameFilter(game) {
      let retVal = vm.filters.game === ALL;
      if (!retVal) {
        if (vm.filters.game === 'Home') {
          retVal = game.homeTeam.highlight;
        } else if (vm.filters.game === 'Away') {
          retVal = game.awayTeam.highlight;
        }
      }
      return retVal;
  }

  function resultFilter(game) {
      let retVal = vm.filters.result === ALL;
      if (!retVal) {
        if (vm.filters.result === 'Home Win') {
          retVal = game.homeTeam.scored > game.awayTeam.scored;
        } else if (vm.filters.result === 'Away Win') {
          retVal = game.homeTeam.scored < game.awayTeam.scored;
        } else if (vm.filters.result === 'Draw') {
          retVal = game.homeTeam.scored === game.awayTeam.scored;
        }
      }
      return retVal;
  }

  function startingPlayerFilter(game) {
    let retVal = vm.filters.startingPlayers === ALL;
    if (!retVal) {
      // Assume the game will filter through
      retVal = true;
      // Create an array of the names of players in the starting XI
      const startingPlayerNames = game.startingXI.map((sP) => sP.player.name);
      // Iterate through the array of names in the filter
      vm.filters.startingPlayers.forEach((sP) => {
        // Perform a logical AND with the current return value
        // and if the array of starting player names includes the name in the filter
        retVal = retVal && startingPlayerNames.includes(sP);
      });
    }
    return retVal;
  }

  function substitutePlayerFilter(game) {
    let retVal = vm.filters.substitutePlayers === ALL;
    if (!retVal) {
      // Assume the game will filter through
      retVal = true;
      // Create an array of the names of players in the starting XI
      const substitutePlayerNames = game.substitutes.map((sP) => sP.player.name);
      // Iterate through the array of names in the filter
      vm.filters.substitutePlayers.forEach((sP) => {
        // Perform a logical AND with the current return value
        // and if the array of starting player names includes the name in the filter
        retVal = retVal && substitutePlayerNames.includes(sP);
      });
    }
    return retVal;
  }
}
