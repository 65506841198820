angular
  .module('app')
  .directive('share', share);

function share() {
  return {
    restrict: 'E',
    scope: {
      slug: '=slug',
      text: '=text',
      url: '=url'
    },
    templateUrl: 'app/common/directives/share/share.html'
  };
}
