angular
  .module('app')
  .controller('CompetitionsController', competitionsController);

function competitionsController(competitionsData) {
  const vm = this;

  const labels = ['Goalkeepers', 'Defenders', 'Midfielders', 'Strikers', 'Loanees'];
  competitionsData.forEach(d => {
    d['tableOrGraph'] = 'table';
    d.labels = labels;
  });
  vm.competitions = competitionsData;
}
