angular
  .module('app')
  .controller('PlayersController', playersController);

function playersController(playersData) {
  const vm = this;

  vm.playersByLetter = [];
  vm.filter = '';

  vm.filterPlayers = filterPlayers;
  vm.clearFilter = clearFilter;

  let players = playersData;
  filterPlayers();

  function clearFilter() {
    vm.filter = '';
    filterPlayers();
  }

  function filterPlayers() {
    vm.playersByLetter = [];
    const playersByLetter = players.reduce(reducePlayersBySurName, {})
    for (let letter in playersByLetter) {
      if (playersByLetter[letter].players.length > 0) {
        playersByLetter[letter].isOpen = (playersByLetter[letter].players.length <= 5);
        playersByLetter[letter].players.sort((p1, p2) => {
          let sortOrder = p1.surName.localeCompare(p2.surName);
          if (sortOrder === 0 ) {
            sortOrder = p1.firstName.localeCompare(p2.firstName);
          }
          return sortOrder;
        });
        vm.playersByLetter.push(playersByLetter[letter]);
      }
    }
    vm.playersByLetter = vm.playersByLetter.sort((pBL1, pBL2) => pBL1.letter.localeCompare(pBL2.letter));
  }

  function reducePlayersBySurName(result, player) {
    const firstLetter = player.surName.charAt(0).toUpperCase();
    if (angular.isUndefined(result[firstLetter])) {
      result[firstLetter] = {
        letter: firstLetter,
        players: []
      }
    }
    const pBL = result[firstLetter];
    const noFilter = vm.filter.length === 0;
    const firstNameMatchesFilter = (!noFilter) && player.firstName.toLowerCase().indexOf(vm.filter.toLowerCase()) > -1;
    const surNameMatchesFilter = (!noFilter) && player.surName.toLowerCase().indexOf(vm.filter.toLowerCase()) > -1;
    const addPlayer = (noFilter || firstNameMatchesFilter || surNameMatchesFilter);
    if (addPlayer) {
      pBL.players.push(player);
    }
    return result;
  }
}
