angular
  .module('app')
  .run(run);

/** @ngInject */
function run($rootScope, $transitions, $interval) {
  let labelInterval;
  $rootScope.spinner = {
    active: false,
    label: 'Please wait',
    labels: [],
    counter: 0,
    on: (strings) => {
      $rootScope.spinner.counter = 0;
      $rootScope.spinner.labels = strings;
      $rootScope.spinner.label = 'Please wait';
      $rootScope.spinner.active = true;
      labelInterval = $interval(() => {
        if ($rootScope.spinner.labels.length > 0) {
          if ($rootScope.spinner.counter % 2 === 0) {
            $rootScope.spinner.label = $rootScope.spinner.labels.shift();
          } else {
            $rootScope.spinner.label = 'Please wait';
          }
          $rootScope.spinner.counter++;
        }
      }, 2000);
    },
    off: () => {
      $interval.cancel(labelInterval);
      $rootScope.spinner.active = false;
    }
  };

  $transitions.onStart({}, (transition) => {
    $rootScope.spinner.on(transition.to().resolve.strings());
  });

  $transitions.onSuccess({from: 'live'}, (transition) => {
    if (angular.isDefined($rootScope.update)) {
      $interval.cancel($rootScope.update);
    }
  });

  $transitions.onSuccess({}, (trans) => {
    $rootScope.spinner.off();
  });
}
