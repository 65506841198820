angular
  .module('app')
  .directive('playerSeasonLink', playerSeasonLink);

function playerSeasonLink() {
  return {
    restrict: 'E',
    scope: {
      player: '=player',
      seasonRef: '=seasonRef'
    },
    templateUrl: 'app/common/directives/playerSeasonLink/playerSeasonLink.html'
  };
}
