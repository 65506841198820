angular
  .module('app')
  .controller('PlayerController', playerController);

function playerController($state, $stateParams, playerData, moment) {
  const vm = this;
  vm.seasons = [];
  vm.summary = {};
  vm.selectSeason = selectSeason;

  const data = playerData;

  vm.player = data.player;
  vm.player.age = moment().diff(moment(vm.player.dateOfBirth), 'years');
  vm.seasons = data.seasons.filter(s => s.games.length > 0).map(s => s.seasonRef);
  let seasonRef = '';
  if (angular.isDefined($stateParams.seasonRef)) {
    vm.seasonIndex = vm.seasons.indexOf($stateParams.seasonRef);
    if (vm.seasonIndex < 0) {
      vm.seasonIndex = (vm.seasons.length - 1);
    } else {
      seasonRef = vm.seasons[vm.seasonIndex];
    }
  } else {
    vm.seasonIndex = (vm.seasons.length - 1);
  }
  vm.slug = $stateParams.slug + '/' + seasonRef;
  $state.go('player.season', {seasonRef: seasonRef, filters: vm.filters});
  
  function selectSeason(seasonRef) {
    vm.slug = $stateParams.slug + '/' + seasonRef;
    vm.seasonIndex = vm.seasons.indexOf(seasonRef);
    $state.go('player.season', {seasonRef: seasonRef, filters: vm.filters});
  }
}
