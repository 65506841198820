angular
  .module('app')
  .controller('HomeController', homeController);

function homeController($sce, PostsService, recentPosts, $log) {
  const vm = this;

  vm.toTrusted = toTrusted;
  vm.blogs = recentPosts;
  vm.getPosts = getPosts;

  function toTrusted(html) {
    return $sce.trustAsHtml(html);
  }

  function getPosts() {
    const posts = PostsService.getNextPosts();
    posts.then(data => vm.blogs = [...vm.blogs, ...data]);
  }
}
