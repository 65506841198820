angular
  .module('app')
  .directive('verticalToggle', verticalToggle);

function verticalToggle() {
  return {
    restrict: 'E',
    scope: {
      id: '=id',
      topValue: '=topValue',
      bottomValue: '=bottomValue',
      model: '=model'
    },
    templateUrl: 'app/common/directives/verticalToggle/verticalToggle.html'
  };
}
