angular.module('app', ['angularMoment', 
                       'chart.js', 
                       'ngAnimate', 
                       'ngMap', 
                       'ngResource',
                       'ngSanitize', 
                       'pascalprecht.translate', 
                       'smart-table', 
                       'ui.router',
                       'ui.bootstrap']);
