angular
  .module('app')
  .controller('PositionsController', positionsController);

function positionsController(positionsData) {
  const vm = this;

  positionsData.forEach((item, index) => {
    item['tableOrGraph'] = 'table';
    item['total'] = item.tournaments.reduce((accum,item) => accum + item.total, 0);
    const chartData = [];
    const chartLabels = [];
    item.tournaments.forEach((tournament) => {
      chartData.push(tournament.total);
      chartLabels.push(tournament.tournament.name);
    });
    item['chartData'] = chartData
    item['chartLabels'] = chartLabels;
  })
  vm.positions = positionsData;
}
