angular
  .module('app')
  .controller('BlogController', blogController);

function blogController($stateParams, $http, $sce, $state) {
  const vm = this;
  const postsUrl = 'https://www.googleapis.com/blogger/v3/blogs/6729460513040542953/posts';

  vm.toTrusted = toTrusted;

  const config = {
    method: 'GET',
    params: {
      key: 'AIzaSyCQGodOQz_ISgHCeppOskqTJDXiW9WJ2kg',
    },
  };

  if (angular.isDefined($stateParams.id)) {
    const blogId = $stateParams.id;
    const postUrl = `${postsUrl}/${blogId }`;
    $http.get(postUrl, config)
         .then((response) => {
        if (response.data.url.endsWith($stateParams.url)) {
          setBlog(response.data);
        } else {
          $state.go('blog');
        }
      }, (error) => {
      });
  } else {
    $http.get(postsUrl, config)
      .then(response => {
        setBlog(response.data.items[0]);
      });
  }

  function toTrusted(html) {
    return $sce.trustAsHtml(html);
  }

  function setBlog(blog) {
    vm.blog = {
      id: blog.id,
      title: blog.title,
      content: blog.content,
      published: blog.published,
      labels: blog.labels,
      url: blog.url,
    };
  }
}
