angular
  .module('app')
  .directive('playerLink', playerLink);

function playerLink() {
  return {
    restrict: 'E',
    scope: {
      player: '=player'
    },
    templateUrl: 'app/common/directives/playerLink/playerLink.html'
  };
}
