angular
  .module('app')
  .factory('CompetitionsService', competitionsService);

function competitionsService($resource, $cacheFactory) {
  const competitionsCache = $cacheFactory('competitions');
  return $resource('api/competitions/:slug', {slug: '@slug'}, {
    'getCompetitions': {method: 'GET', cache: competitionsCache, isArray: true},
    'getCompetition': {method: 'GET', cache: competitionsCache}
  });
}
