angular
  .module('app')
  .directive('gameDaySummary', gameDaySummary);

function gameDaySummary() {
  return {
    restrict: 'E',
    scope: {
      competitions: '=competitions',
      detailsHeight: '=detailsHeight'
    },
    templateUrl: 'app/gameDay/summary/summary.html'
  };
}
