angular
  .module('app')
  .directive('graph', graph);

function graph() {
  return {
    restrict: 'E',
    scope: {
      configuration: '=configuration'
    },
    templateUrl: 'app/players/player/playerSeasonSummary/graph/graph.html'
  };
}
