angular
  .module('app')
  .controller('CompetitionController', competitionController);

function competitionController($document, $stateParams, $timeout, competitionData, NgMap) {
  const vm = this;

  vm.competitionHeight = 0;

  vm.displayMap = displayMap;
  vm.showInfoWindow = showInfoWindow;

  const validSlugs = ['premier-league', 'championship', 'league-one', 'league-two', 'scottish-premiership', 'scottish-championship'];
  const slug = validSlugs.indexOf($stateParams.slug) > -1 ? $stateParams.slug : 'premier-league';
  vm.competition = competitionData;
  $timeout(displayMap, 500);

  function displayMap() {
    vm.competitionHeight = angular.element($document[0].getElementById('competition-div'))[0].scrollHeight; 
    vm.map = NgMap.initMap('competition-ng-map');
  }

  function showInfoWindow(event, team) {
    vm.club = team.club;
    vm.signees = team.signees;
    vm.loanees = team.loanees;
    vm.map.showInfoWindow('club-info-window', this); // eslint-disable-line angular/controller-as-vm
  }
}
