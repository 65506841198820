angular
  .module('app')
  .controller('GameDayController', gameDayController);

function gameDayController($document, $scope, $state, $timeout, NgMap, gameDayData) {
  const vm = this;

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const afterTomorrow = new Date();
  afterTomorrow.setDate(tomorrow.getDate() + 1);
  const events = [
    {
      date: tomorrow,
      status: 'full'
    },
    {
      date: afterTomorrow,
      status: 'partially'
    }
  ];

  vm.data = gameDayData;
  angular.forEach(vm.data.competitions, function(competition) {
    const total = competition.summary.reduce(function(total, num) {
      return total + parseInt(num.total);
    }, 0);
    competition['total'] = total;
  });
  vm.date = gameDayData.date != null ? new Date(gameDayData.date) : new Date(gameDayData.requestedDate);
  vm.lastDate = new Date(gameDayData.lastDate);
  vm.slug = `${vm.date.getFullYear()}/${prependZero(vm.date.getMonth() + 1)}/${prependZero(vm.date.getDate())}`;

  $scope.$watch(angular.bind(vm, function () {
    return vm.date;
  }), function (date) {
    const month = prependZero(date.getMonth() + 1);
    const day = prependZero(date.getDate());
    $state.go('gameDay.date', {year: date.getFullYear(), month: month, day: day});  
  });
  
  vm.detailsHeight = 0;

  vm.displayMap = displayMap;

  vm.inlineOptions = {
    minDate: new Date(),
    showWeeks: true
  };

  vm.dateOptions = {
    formatYear: 'yy',
    maxDate: vm.lastDate,
    minDate: new Date("1992-08-15"),
    startingDay: 1,
    showButtonBar: false
  };

  function displayMap() {
    $timeout(function() {
      vm.detailsHeight = angular.element($document[0].getElementById('gameday-summary-panel'))[0].scrollHeight; 
      vm.map = NgMap.initMap('gameday-summary-ng-map');
    }, 500);
  }

  function prependZero(value) {
    return (value < 10) ? "0" + value : value;
  }
}
