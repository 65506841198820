angular
  .module('app')
  .factory('PlayersService', playersService);

function playersService($resource, $cacheFactory) {
  const playersCache = $cacheFactory('players');
  return $resource('api/players/:slug', {slug: '@slug'}, {
    'getPlayers': {method: 'GET', cache: playersCache, isArray: true},
    'getPlayer': {method: 'GET', cache: playersCache}
  });
}
