angular
  .module('app')
  .directive('substitutes', substitutes);

function substitutes() {
  return {
    restrict: 'EA',
    scope: {
      substitutePlayers: '=substitutePlayers',
      club: '=club',
    },
    templateUrl: 'app/gameDay/details/lineup/substitutes/substitutes.html',
  };
}
