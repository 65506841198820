angular
  .module('app')
  .service('ClubSeasonDataService', clubSeasonDataService);

function clubSeasonDataService($cacheFactory) {
  const clubSeasonDataCache = $cacheFactory('ClubSeasonData');

  this.addClubSeasonData = addClubSeasonData;
  this.getClubSeasonData = getClubSeasonData;

  function addClubSeasonData(clubSlug, seasonData) {
    if (angular.isUndefined(clubSeasonDataCache.get(clubSlug))) {
      const clubSeasonData = {}
      angular.forEach(seasonData.seasons, function(season) {
        this[season.seasonRef] = season;
      }, clubSeasonData);
      clubSeasonData['default'] = seasonData.seasons[seasonData.seasons.length - 1];
      clubSeasonDataCache.put(clubSlug, clubSeasonData);
    }
  }

  function getClubSeasonData(clubSlug, seasonRef) {
    const clubSeasonData = clubSeasonDataCache.get(clubSlug);
    return clubSeasonData[seasonRef];
  }
}
