angular
  .module('app')
  .directive('clubSeasonLink', clubSeasonLink);

function clubSeasonLink() {
  return {
    restrict: 'E',
    scope: {
      club: '=club',
      seasonRef: '=seasonRef'
    },
    templateUrl: 'app/common/directives/clubSeasonLink/clubSeasonLink.html'
  };
}
