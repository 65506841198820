angular
  .module('app')
  .factory('GameDayService', gameDayService);

function gameDayService($resource, $cacheFactory) {
  const gameDayCache = $cacheFactory('gameDay');
  return $resource('api/gameDay/:slug', {slug: '@slug'}, {
    'getGameDay': {method: 'GET', cache: gameDayCache}
  });
}
