angular
  .module('app')
  .factory('LiveService', liveService);

function liveService($resource, $cacheFactory) {
  const liveCache = $cacheFactory('liveGames');
  return $resource('api/live', {}, {
    'getLiveGames': {method: 'GET'},
  });
}
