angular
  .module('app')
  .directive('lineup', lineup);

function lineup() {
  return {
    restrict: 'EA',
    scope: {
      team: '=team'
    },
    templateUrl: 'app/gameDay/details/lineup/lineup.html',
  };
}
