angular
  .module('app')
  .controller('LiveController', liveController);

function liveController($interval, $rootScope, LiveService, live, moment) {
  const vm = this;

  vm.aertel = false;
  vm.currentTime = moment();

  vm.$onDestroy = () => {
    $interval.cancel(updateTimeInterval);
  };

  const updateLiveGames = () => {
    LiveService.getLiveGames().$promise.then((response) => {
      vm.competitions = response.liveGames.reduce(generateCompetitions, []);
      vm.gamesInProgress = response.gamesInProgress;
      if (!vm.gamesInProgress) {
        $interval.cancel($rootScope.update);
      }
    });
  };

  const generateCompetitions = (result, liveGame) => {
    let competition = result.find(r => r.id === liveGame.competitionId);
    if (angular.isUndefined(competition)) {
      competition = {
        id: liveGame.competitionId,
        name: liveGame.competitionName,
        games: []
      }
      result.push(competition);
    }
    competition.games.push(liveGame.gameHTML);
    return result;
  };

  const updateTime = () => vm.currentTime = moment();
  const updateTimeInterval = $interval(updateTime, 1000);

  vm.competitions = live.liveGames.reduce(generateCompetitions, []);
  vm.gamesInProgress = live.gamesInProgress;
  $rootScope.update = vm.gamesInProgress ? $interval(updateLiveGames, 7500) : null;
}
