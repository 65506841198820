angular
  .module('app')
  .config(config);

/** @ngInject */
function config($stateProvider, $urlRouterProvider, $locationProvider, $translateProvider, $compileProvider) {
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|whatsapp):/);
  $locationProvider.html5Mode(true);
  $urlRouterProvider.otherwise('/');

  $stateProvider
    .state('home', {
      url: '/',
      resolve: { /* @ngInject */
        recentPosts: function(PostsService) {
          return PostsService.getRecentPosts();
        },
        strings: () => ['Getting posts']
      },
      views: {
        'main@': {
          controller: 'HomeController',
          controllerAs: 'homeController',
          templateUrl: 'app/main.html'
        }
      }
    })
    .state('blogById', {
      url: '/blog/{id}/{url}',
      resolve: { /* @ngInject */
        strings: () => ['Getting post']
      },
      views: {
        'main@': {
          controller: 'BlogController',
          controllerAs: 'blogController',
          templateUrl: 'app/blog/blog.html'
        }
      }
    })
    .state('players', {
      url: '/players',
      resolve: { /* @ngInject */
        playersData: function(PlayersService, $rootScope) {
                    return PlayersService.getPlayers()
                               .$promise
                               .then(response => response);
        },
        strings: () => ['Getting players', 'Getting player data', 'Ordering players']
      },
      views: {
        'main@': {
          controller: 'PlayersController',
          controllerAs: 'playersController',
          templateUrl: 'app/players/players.html',
        }
      }
    })
    .state('player', {
      url: '/players/{slug}',
      resolve: { /* @ngInject */
        playerData: function($stateParams, PlayersService, PlayerSeasonDataService, $rootScope) {
                    return PlayersService.getPlayer({slug: $stateParams.slug})
                               .$promise
                               .then((response) => {
                                  PlayerSeasonDataService.addPlayerSeasonData($stateParams.slug, response);
                                  return response;
                                });
        },
        strings: () => ['Getting player', 'Getting appearance data', 'Processing player data', 'Creating graphs']
      },
      views: {
        'main@': {
          controller: 'PlayerController',
          controllerAs: 'playerController',
          templateUrl: 'app/players/player/player.html'
        }
      }
    })
  .state('player.season', {
      url: '/{seasonRef}',
      resolve: { /* @ngInject */
        playerSeasonData: function(PlayerSeasonDataService, $stateParams) {
          const seasonRef = $stateParams.seasonRef === '' ? 'default' : $stateParams.seasonRef;
          return PlayerSeasonDataService.getPlayerSeasonData($stateParams.slug, seasonRef);
        },
        strings: () => ['Processing player season data']
      },
      views: {
        'playerSeasonGames@player': {
          controller: 'PlayerSeasonGamesController',
          controllerAs: 'playerSeasonGamesController',
          templateUrl: 'app/players/player/playerSeasonGames/playerSeasonGames.html'
        },
        'playerSeasonSummary@player': {
          controller: 'PlayerSeasonSummaryController',
          controllerAs: 'playerSeasonSummaryController',
          templateUrl: 'app/players/player/playerSeasonSummary/playerSeasonSummary.html',
        }
      }
    })
    .state('competitions', {
      url: '/competitions',
      resolve: { /* @ngInject */
        competitionsData: function(CompetitionsService, $rootScope) {
          return CompetitionsService.getCompetitions().$promise.then((response) => response);
        },
        strings: () => ['Getting competitions', 'Getting competition teams', 'Creating player links']
      },
      views: {
        'main@': {
          controller: 'CompetitionsController',
          controllerAs: 'competitionsController',
          templateUrl: 'app/competitions/competitions.html'
        }
      }
    })
    .state('competitions.details', {
      url: '/{slug}',
      resolve: { /* @ngInject */
        competitionData: function(CompetitionsService, $stateParams, $rootScope) {
          return CompetitionsService.getCompetition({slug: $stateParams.slug}).$promise.then((response) => response);
        },
        strings: () => ['Getting competitions', 'Getting competition teams', 'Getting players', 'Organising by position']
      },
      views: {
        'main@': {
          controller: 'CompetitionController',
          controllerAs: 'competitionController',
          templateUrl: 'app/competitions/competition/competition.html'
        }
      }
    })
    .state('gameDay', {
      url: '/gameDay',
      resolve: { /* @ngInject */
        gameDayData: function(GameDayService, $rootScope) {
          return GameDayService.getGameDay().$promise.then((response) => response);
        },
        strings: () => ['Getting date', 'Getting games', 'Getting lineups', 'Calculating summary']
      },
      views: {
        'main@': {
          templateUrl: 'app/gameDay/gameDay.html',
          controller: 'GameDayController',
          controllerAs: 'gameDayController',
        }
      }
    })
    .state('gameDay.date', {
      url: '/gameDay/{year}/{month}/{day}',
      resolve: { /* @ngInject */
        gameDayData: function($stateParams, GameDayService, $rootScope) {
                    const year = $stateParams.year;
          const month = (parseInt($stateParams.month) < 10) ? '0' + parseInt($stateParams.month) : $stateParams.month;
          const day = (parseInt($stateParams.day) < 10) ? '0' + parseInt($stateParams.day) : $stateParams.day;
          const slug = `${year}-${month}-${day}`;
          return GameDayService.getGameDay({slug: slug}).$promise.then((response) => response);
        },
        strings: () => ['Getting competitions', 'Getting lineups', 'Caclulating summary']
      },
      views: {
        'main@': {
          templateUrl: 'app/gameDay/gameDay.html',
          controller: 'GameDayController',
          controllerAs: 'gameDayController',
        }
      }
    })
    .state('live', {
      url: '/live',
      resolve: { /* @ngInject */
        live: function(LiveService, $rootScope) {
          return LiveService.getLiveGames().$promise.then((response) => {
            return response;
          }, (error) => {
            return {};
          });
        },
        strings: () => ['Getting live data', 'Getting competitions', 'Getting competition teams', 'Getting players']
      },
      views: {
        'main@': {
          controller: 'LiveController',
          controllerAs: 'liveController',          
          templateUrl: 'app/live/live.html'
        }
      }      
    })
    .state('positions', {
      url: '/positions',
      resolve: { /* @ngInject */
        positionsData: function(PositionsService, $rootScope) {
          return PositionsService.getPositions().$promise.then((response) => response);
        },
        strings: () => ['Getting players', 'Organising by position', 'Calculating summary']
      },
      views: {
        'main@': {
          controller: 'PositionsController',
          controllerAs: 'positionsController',          
          templateUrl: 'app/positions/positions.html'
        }
      }
    })
    .state('positions.details', {
      url: '/{slug}',
      resolve: { /* @ngInject */
        positionData: function($stateParams, PositionsService, $rootScope) {
          return PositionsService.getPosition({slug: $stateParams.slug}).$promise.then((response) => response);
        },
        strings: () => ['Getting players', 'Creating map']
      },
      views: {
        'main@': {
          controller: 'PositionController',
          controllerAs: 'positionController',
          templateUrl: 'app/positions/position/position.html'
        }
      }
    })
    .state('clubs', {
      url: '/clubs',
      resolve: { /* @ngInject */
        clubsData: function(ClubsService, $rootScope) {
          return ClubsService.getClubs().$promise.then((response) => response);
        },
        strings: () => ['Getting clubs', 'Organising by competition', 'Applying filters']
      },
      views: {
        'main@': {
          controller: 'ClubsController',
          controllerAs: 'clubsController',
          templateUrl: 'app/clubs/clubs.html'
        }
      }
    })
    .state('club', {
      url: '/clubs/{slug}',
      resolve: { /* @ngInject */
        clubData: function($stateParams, ClubsService, ClubSeasonDataService, $rootScope) {
          $rootScope.spinner.active = true;          
          return ClubsService.getClub({slug: $stateParams.slug}).$promise.then((response) => {
            ClubSeasonDataService.addClubSeasonData($stateParams.slug, response);
            return response;
          });
        },
        strings: () => ['Getting club', 'Getting season data', 'Getting players']
      },
      views: {
        'main@': {
          controller: 'ClubController',
          controllerAs: 'clubController',
          templateUrl: 'app/clubs/club/club.html'
        }
      }
    })
  .state('club.season', {
      url: '/{seasonRef}',
      resolve: { /* @ngInject */
        clubSeasonData: function(ClubSeasonDataService, $stateParams) {
          const seasonRef = $stateParams.seasonRef === '' ? 'default' : $stateParams.seasonRef;
          return ClubSeasonDataService.getClubSeasonData($stateParams.slug, seasonRef);
        },
        strings: () => ['Getting club', 'Getting season data', 'Getting players']
      },
      views: {
        'clubSeasonGames@club': {
          controller: 'ClubSeasonGamesController',
          controllerAs: 'clubSeasonGamesController',
          templateUrl: 'app/clubs/club/clubSeasonGames/clubSeasonGames.html'
        }
      }
    })
  .state('links', {
      url: '/links',
      resolve: {
        strings: () => ['Creating links']
      },
      views: {
        'main@': {
          controller: 'LinksController',
          controllerAs: 'linksController',
          templateUrl: 'app/links/links.html'
        }
      }
    })
    .state('privacy-policy', {
      url: '/privacy-policy',
      resolve: {
        strings: () => ['Opening privacy policy']
      },
      views: {
        'main@': {
          controller: 'PrivacyPolicyController',
          controllerAs: 'privacyPolicyController',
          templateUrl: 'app/privacyPolicy/privacyPolicy.html'
        }
      }
    });
  $translateProvider.useStaticFilesLoader({
    prefix: '/app/i18n/',
    suffix: '.json',
  });
  $translateProvider.preferredLanguage('en');
  $translateProvider.useSanitizeValueStrategy('sanitize');
}
