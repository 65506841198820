angular
  .module('app')
  .controller('ClubController', clubController);

function clubController($state, $stateParams, clubData) {
  const vm = this;

  vm.selectSeason = selectSeason;

  vm.club = clubData.club;
  vm.positionPlayers = clubData.positionPlayers;
  vm.loanees = clubData.loanees;
  vm.seasons = clubData.seasons.filter(s => s.games.length > 0).map(s => s.seasonRef);

  let seasonRef = '';
  if (angular.isDefined($stateParams.seasonRef)) {
    vm.seasonIndex = vm.seasons.indexOf($stateParams.seasonRef);
    if (vm.seasonIndex < 0) {
      vm.seasonIndex = (vm.seasons.length - 1);
    } else {
      seasonRef = vm.seasons[vm.seasonIndex];
    }
  } else {
    vm.seasonIndex = (vm.seasons.length - 1);
  }
  vm.slug = $stateParams.slug + '/' + seasonRef;
  $state.go('club.season', {seasonRef: seasonRef});

  function selectSeason(seasonRef) {
    vm.slug = $stateParams.slug + '/' + seasonRef;
    vm.seasonIndex = vm.seasons.indexOf(seasonRef);
    $state.go('club.season', {seasonRef: seasonRef});
  }
}
