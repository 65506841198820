angular
  .module('app')
  .directive('clubsGroup', clubsGroup);

function clubsGroup() {
  return {
    restrict: 'E',
    scope: {
      tournament: '=tournament'
    },
    templateUrl: 'app/clubs/clubsGroup/clubsGroup.html'
  };
}
