angular
  .module('app')
  .directive('gameDayDetails', gameDayDetails);

function gameDayDetails() {
  return {
    restrict: 'E',
    scope: {
      competitions: '=competitions'
    },
    templateUrl: 'app/gameDay/details/details.html'
  };
}
