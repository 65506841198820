angular
  .module('app')
  .controller('PlayerSeasonGamesController', playerSeasonGamesController);

function playerSeasonGamesController(playerSeasonData, $stateParams, $uibModal) {
  const vm = this;

  const ALL = 'All';

  vm.filters = {
    competition: ALL,
    game: ALL,
    result: ALL,
    appearance: ALL,
    scoring: ALL,
    discipline: ALL
  };

  vm.openFilter = openFilter;
  vm.games = playerSeasonData.games;
  vm.seasonRef = $stateParams.seasonRef === '' ? null : $stateParams.seasonRef;

  function openFilter() {
    const modalInstance = $uibModal.open({
      animation: true,
      component: 'playerFilterModalComponent',
      resolve: {
        filters: () => vm.filters,
        competitions: () => Array.from(new Set(playerSeasonData.games.map(g => g.competition.name)))
      }
    });

    modalInstance.result.then((filters) => {
      vm.filters = filters;
      vm.games = playerSeasonData.games
                                 .filter(competitionFilter)
                                 .filter(gameFilter)
                                 .filter(resultFilter)
                                 .filter(appearanceFilter)
                                 .filter(scoringFilter)
                                 .filter(disciplineFilter);

    }, () => angular.noop);
  }

  function competitionFilter(game) {
    return [ALL, game.competition.name].includes(vm.filters.competition);
  }

  function gameFilter(game) {
      let retVal = vm.filters.game === ALL;
      if (!retVal) {
        if (vm.filters.game === 'Home') {
          retVal = game.homeTeam.highlight;
        } else if (vm.filters.game === 'Away') {
          retVal = game.awayTeam.highlight;
        }
      }
      return retVal;
  }

  function resultFilter(game) {
      let retVal = vm.filters.result === ALL;
      if (!retVal) {
        if (vm.filters.result === 'Home Win') {
          retVal = game.homeTeam.scored > game.awayTeam.scored;
        } else if (vm.filters.result === 'Away Win') {
          retVal = game.homeTeam.scored < game.awayTeam.scored;
        } else if (vm.filters.result === 'Draw') {
          retVal = game.homeTeam.scored === game.awayTeam.scored;
        }
      }
      return retVal;
  }

  function appearanceFilter(game) {
    let retVal = vm.filters.appearance === ALL;
    if (!retVal) {
      switch (vm.filters.appearance) {
        case 'Started':
            retVal = game.startingPlayer
          break;

        case 'Completed':
            retVal = game.startingPlayer && !game.startingPlayer.substitutedOn
          break;

        case 'Substituted': 
            retVal = (game.startingPlayer && game.startingPlayer.substitutedOn > 0) || (game.substitutePlayer && game.substitutePlayer.substitutedOn)
          break;

        case 'Substitute':
            retVal = game.substitutePlayer
          break;

        case 'Introduced':
            retVal = game.substitutePlayer && game.substitutePlayer.introducedOn
          break;

        case 'Unused Substitute':
            retVal = game.substitutePlayer && !game.substitutePlayer.introducedOn
          break;

        default:
          retVal = true; 
          break;
      }
    }
    return retVal;
  }

  function scoringFilter(game) {
    let retVal = vm.filters.scoring === ALL;
    if (!retVal) {
      if (vm.filters.scoring === 'Scored') {
        retVal = game.goals.filter(g => !g.isOG).length > 0;
      } else if (vm.filters.scoring === 'Did Not Score') {
        retVal = game.goals.length === 0;
      }
    }
    return retVal;
  }

  function disciplineFilter(game) {
    let retVal = vm.filters.discipline === ALL;
    if (!retVal) {
      switch (vm.filters.discipline) {
        case 'Not disciplined':
          retVal = !game.booked && !game.sentOff;
          break;

        case 'Booked':
          retVal = game.booked;
          break;
          
        case 'Sent Off':
          retVal = game.sentOff;
          break;

        default:
          retVal = true;
          break;
      }
    }
    return retVal;
  }
}
