angular
  .module('app')
  .controller('PlayerSeasonSummaryController', playerSeasonSummary);

function playerSeasonSummary(playerSeasonData) {
  const vm = this;
  const playerSeasonSummary = playerSeasonData.details;

  const dataSetOverride = [{
      backgroundColor: ['#e87e04', '#1aaf5d'],
      borderWidth: 0.5
    },{
      backgroundColor: ['#e94b35', '#e94b35', '#1fce6d', '#00bd9c'],
      borderWidth: 0.5
    }];


  vm.labels = ['Home', 'Away'];
  const startLabels = [
    ["Starts", "Starts"],
    ["Completed", "Substituted", "Completed", "Substituted"]
  ];

  const substituteLabels = [
    ["Substitute", "Substitute"],
    ["Unused", "Introduced", "Unused", "Introduced"]
  ];

  const goalsLabels = [
    ['Scored', 'Scored'],
    ['Goals', 'Penalties', 'Goals', 'Penalties']
  ];

  const disciplineLabels = [
    ['Bookings', 'Bookings'],
    ['Sendings Off', 'Sendings Off']
  ];

  vm.tabs = {
    starts: generateStartingPlayerData(playerSeasonSummary),
    substitute: generateSubstituteData(playerSeasonSummary),
    goals: generateGoalsData(playerSeasonSummary),
    discipline: generateDisciplineData(playerSeasonSummary)
  };

  vm.startsSubsActiveTabIndex = vm.tabs.starts.table.rows[0][3] === 0 ? 1 : 0;
  vm.goalsDisciplineActiveTabIndex = vm.tabs.goals.table.rows[0][3] === 0 ? 1 : 0;

  function generateStartingPlayerData(details) {
    const homeStarts = details.starts.home;
    const awayStarts = details.starts.away;
    const starts = [awayStarts, homeStarts];

    const homeSubstituted = homeStarts - details.completed.home;
    const awaySubstituted = awayStarts - details.completed.away;
    const startsBreakdown = [details.completed.away, awaySubstituted, details.completed.home, homeSubstituted];
    return {
      id: 'starts',
      label: 'players.starts',
      display: (homeStarts + awayStarts > 0),
      data: [starts, startsBreakdown],
      dataSetOverride: dataSetOverride, 
      options: generateGraphOptions(startLabels),
      tableOrGraph: 'graph',
      table: {
        head: ['', 'H', 'A', 'Tot'],
        rows: [
          ['Starts', homeStarts, awayStarts, (homeStarts + awayStarts)],
          ['Completed', details.completed.home, details.completed.away, (details.completed.home + details.completed.away)],
          ['Substituted', homeSubstituted, awaySubstituted, (homeSubstituted + awaySubstituted)]
        ]
      }
    };
  }
  
  function generateSubstituteData(details) {
    const homeNamedOnBench = details.startedOnBench.home;
    const awayNamedOnBench = details.startedOnBench.away;
    const namedOnBench = [awayNamedOnBench, homeNamedOnBench];

    const homeUnusedSubstitute = details.startedOnBench.home - details.introductions.home;
    const awayUnusedSubstitute = details.startedOnBench.away - details.introductions.away;
    const namedOnBenchBreakdown = [details.introductions.away, awayUnusedSubstitute, details.introductions.home, homeUnusedSubstitute];
    return {
      id: 'substitute',
      label: 'players.substitute',
      labels: [],
      display: (homeNamedOnBench + awayNamedOnBench > 0),
      data: [namedOnBench, namedOnBenchBreakdown],
      dataSetOverride: dataSetOverride, 
      options: generateGraphOptions(substituteLabels),
      tableOrGraph: 'graph',
      table: {
        head: ['', 'H', 'A', 'Tot'],
        rows: [
          ['Substitute', details.startedOnBench.home, details.startedOnBench.away, (details.startedOnBench.home + details.startedOnBench.away)],
          ['Introductions', details.introductions.home, details.introductions.away, (details.introductions.home + details.introductions.away)],
          ['Unused Sub', homeUnusedSubstitute, awayUnusedSubstitute, (homeUnusedSubstitute + awayUnusedSubstitute)]
        ]
      }
    };
  }

  function generateGoalsData(details) {
    const goals = [details.goals.away, details.goals.home];
    const goalsBreakdown = [details.scored.away, details.penalties.away, details.scored.home, details.penalties.away];
    return {
      id: 'goals',
      label: 'players.goals',
      labels: [],
      display: (goals[0] + goals[1] > 0),
      data: [goals, goalsBreakdown],
      dataSetOverride: dataSetOverride, 
      options: generateGraphOptions(goalsLabels),
      tableOrGraph: 'graph',
      table: {
        head: ['', 'H', 'A', 'Tot'],
        rows: [
          ['Goals', details.goals.home, details.goals.away, (details.goals.home + details.goals.away)],
          ['Penalties', details.penalties.home, details.penalties.away, (details.penalties.home + details.penalties.away)],
          ['Scored', details.scored.home, details.scored.away, (details.scored.home + details.scored.away)],
        ]
      }
    };
  }

  function generateDisciplineData(details) {
    const bookings = [details.bookings.away, details.bookings.home];
    const sendingsOff = [details.sendingsOff.away, details.sendingsOff.home];
    return {
      id: 'discipline',
      label: 'players.discipline',
      labels: [],
      display: (details.bookings.away + details.bookings.home + details.sendingsOff.away + details.sendingsOff.home > 0),
      data: [bookings, sendingsOff],
      dataSetOverride: dataSetOverride, 
      options: generateGraphOptions(disciplineLabels),
      tableOrGraph: 'graph',
      table: {
        head: ['', 'H', 'A', 'Tot'],
        rows: [
          ['Bookings', details.bookings.home, details.bookings.away, (details.bookings.home + details.bookings.away)],
          ['Red Cards', details.sendingsOff.home, details.sendingsOff.away, (details.sendingsOff.home + details.sendingsOff.away)]
        ]
      }
    };
  }

  function generateGraphOptions(labels) {
    return {
      tooltips: {
        mode: 'point',
        callbacks: {
          title: (tooltipItems) => {
            const tooltipItem = tooltipItems[0];
            const datasetIndex = tooltipItem.datasetIndex;  // 0 = Outer ring, 1 = Inner ring
            const index = tooltipItem.index;
            var title = '';
            if (datasetIndex === 0) {
              if (index === 0) {
                title = 'Away';
              } else {
                title = 'Home';
              }
            } else {
              if (index < 2) {
                title = 'Away';
              } else {
                title = 'Home';
              }
            }
            return title;
          },
          label: (tooltipItem, data) => {
            const datasetIndex = tooltipItem.datasetIndex;
            const toolTipIndex = tooltipItem.index;
            const dataset = data.datasets[datasetIndex];
            const index = tooltipItem.index;
            return labels[datasetIndex][index] + ": " + dataset.data[index];
          }
        }
      }
    };
  }
}
