angular
  .module('app')
  .directive('dateLink', dateLink);

function dateLink() {
  return {
    restrict: 'E',
    scope: {
      date: '=date'
    },
    controller: function($scope) {
      const date = new Date($scope.date + 'T12:00:00');
      const month = date.getMonth() + 1;;
      const day = date.getDate();
      $scope.year = date.getFullYear();
      $scope.month = (month < 10) ? '0' + month : month;
      $scope.day = (day < 10) ? '0' + day : day;
    },
    templateUrl: 'app/common/directives/dateLink/dateLink.html'
  };
}
