angular
  .module('app')
  .factory('PositionsService', positionsService);

function positionsService($resource, $cacheFactory) {
  const positionsCache = $cacheFactory('positions');
  return $resource('api/positions/:slug', {slug: '@slug'}, {
    'getPositions': {method: 'GET', cache: positionsCache, isArray: true},
    'getPosition': {method: 'GET', cache: positionsCache}
  });
}
