angular
  .module('app')
  .directive('spinner', spinner);

function spinner() {
  return {
    restrict: 'E',
    templateUrl: 'app/common/directives/spinner/spinner.html'
  };
}
