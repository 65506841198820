angular
  .module('app')
  .directive('competitionLink', competitionLink);

function competitionLink() {
  return {
    restrict: 'E',
    scope: {
      competition: '=competition'
    },
    templateUrl: 'app/common/directives/competitionLink/competitionLink.html'
  };
}
